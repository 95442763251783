.container-section-payments {
  display: grid;
  grid-template-columns: 30% 70%;
  height: 100dvh;
}

.section-account-payments {
  background: url("https://53fde1b606cff91e7a3b23ff9c242152.grupoalianzacolombia.com/uploads/imagenes_web_priority/IMG_AGUA_MI_CUENTA.webp");
  object-fit: cover;
  display: grid;
  align-items: center;
  justify-content: center;
}

.info-user__details {
  background: rgb(7, 27, 112, 0.8);
  color: white;
  padding: 3em 2em;
  border-radius: 4em;
  backdrop-filter: blur(1px);
  font-style: italic;
}
.info-user__details p span {
  color: var(--amarillo-gac);
}
.container-table-payments {
  background: url("https://53fde1b606cff91e7a3b23ff9c242152.grupoalianzacolombia.com/uploads/imagenes_web_priority/IMG_FONDO_MOTIVOS_MI_CUENTA.webp");
  background-position: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.table-payments {
  background: rgb(0 56 147 / 50%);
  padding: 1em 0 3em 0;
  border-radius: 1em;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.card-active-pay {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -65px;
}

.card-active-pay p {
  color: var(--fondo-defecto);
  font-weight: 700;
  font-size: 1.2rem;
}

.btn-concurrent {
  position: relative;
  font-size: 17px;
  text-decoration: none;
  padding: 0.5em 3.5em;
  display: inline-block;
  border-radius: 3em;
  transition: all 0.2s;
  border: none;
  font-weight: 700;
  color: var(--azul-gac);
  background-color: var(--amarillo-gac);
}

.btn-concurrent:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  color: var(--fondo-defecto);
}
.btn-colombia {
  border: none;
  background: #22408c;
  display: flex;
  box-shadow: 3px 2px 4px #ececec;
  justify-content: space-between;
  border-radius: 0.8em;
  align-items: center;
  width: fit-content;
  padding: 0.8em 2em;
  color: var(--fondo-defecto);
  transition: all 0.3s;
}
.btn-colombia img {
  margin: 0 0.5em;
}
.btn-international {
  border: 1px solid var(--azul-gac);
  background: var(--fondo-defecto);
  display: flex;
  box-shadow: 3px 2px 4px #ececec;
  justify-content: space-between;
  border-radius: 0.8em;
  align-items: center;
  width: fit-content;
  padding: 0.8em 2em;
  color: var(--azul-gac);
  margin: 0.5em 0;
  font-weight: 600;
  transition: all 0.3s;
}
.btn-international img {
  margin: 0 0.5em;
}
.btn-colombia:hover {
  transform: scale(1.1);
}
.btn-international:hover {
  transform: scale(1.1);
}
@media only screen and (max-width: 1100px) {
  .container-section-payments {
    grid-template-columns: 1fr;
    height: 190dvh;
  }
  .container-table-payments {
    background-position: center;
  }
}
