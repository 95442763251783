.option-nav {
  color: var(--azul-gac);
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 0.6em;
  cursor: pointer;
}
.navbar-nav li:not(:last-child) {
  border-right: 1px solid var(--azul-gac);
}
.option-nav:hover {
  text-shadow: 1px 1px var(--azul-gac);
  color: var(--azul-gac);
}
.option-nav.active {
  background: var(--azul-gac);
  color: white;
  border-radius: 1em;
  width: fit-content;
}

.option-nav svg path {
  fill: currentcolor;
  width: 20px;
}
.menu-mobile-gac {
  display: none;
  background: var(--azul-gac);
  color: var(--fondo-defecto);
  border-radius: 2em;
  padding: 0em 0.5em;
  display: none;
  align-items: center;
}
.menu-mobile-gac svg path {
  fill: currentcolor;
}
.menu-mobile-gac .menu-hamburguer {
  color: var(--amarillo-gac);
}
.dropdown-item svg path {
  fill: currentcolor;
  width: 20px;
}
#dropdown {
  border: 0;
}
.svg-navbar {
  width: 30px;
  height: 25px;
}
.nav-link:focus,
.nav-link:hover {
  color: var(--azul-gac);
}
.list-group-item-gac {
  display: flex;
  height: 35px;
  align-items: center;
  font-weight: 500;
}
.list-group-item-gac a {
  display: flex;
  align-items: center;
  color: var(--fondo-defecto);
  font-weight: 500;
  text-decoration: none;
  border-left: 1px dashed var(--fondo-defecto);
  height: 25px;
}

.list-group-item-gac:hover a {
  color: var(--amarillo-gac);
  border-left: 1px dashed var(--amarillo-gac);
}
.divider-gac {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px dashed var(--fondo-defecto);
  opacity: 1;
}
/* clases de bootstrap custom */
.dropdown-menu.show {
  background-color: rgb(7 27 112 / 80%);
  border: none;
  min-width: 260px;
  margin-top: 21px;
  padding: 0.5em;
}
.dropdown-menu.show .dropdown-item {
  background-color: var(--azul-gac);
  color: var(--fondo-defecto);
}
.dropdown-item {
  display: flex;
  justify-content: flex-end;
}
.padding-container {
  padding: 0.8em 2em;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2px);
  width: 90%;
  border-radius: 3em;
  margin: 0 auto;
}

.navbar-expand-lg .offcanvas .offcanvas-body {
  justify-content: center;
}

.offcanvas {
  z-index: 9999;
}

.li-info {
  display: none;
  background: var(--amarillo-gac);
  color: var(--azul-gac);
  padding: 1em;
  border-radius: 1em;
  margin: 1em;
}
.li-info p {
  margin: 0px;
}
@media only screen and (max-width: 1200px) {
  .menu-mobile-gac {
    display: flex;
  }
  .option-nav {
    padding: 0.9em 1em;
  }
  .padding-container {
    width: 97%;
    padding: 0.2em 1em;
    backdrop-filter: blur(1px);
    border-radius: 2em;
  }
  .navbar-brand img {
    width: 124px;
  }
  .navbar-nav li:not(:last-child) {
    border-right: none;
  }
  .option-nav.active {
    width: 100%;
    border-radius: 2em;
  }
  .option-nav:nth-child(4) {
    border-bottom: 1px solid var(--azul-gac);
  }
}
@media only screen and (max-width: 600px) {
  .li-info {
    display: block;
  }
}
