.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, -9px);
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
  z-index: 99999;
  position: relative;
  height: 40px;
}

.swiper-pagination-bullet {
  background-color: var(--gris-gac);
}

.swiper-pagination-bullet-active {
  background-color: var(--amarillo-gac);
}
