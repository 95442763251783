.bg-azul-degrade {
  background-image: url(https://53fde1b606cff91e7a3b23ff9c242152.grupoalianzacolombia.com/uploads/imagenes_web_priority/AZULDEGRADE.webp);
  width: 100%;
  min-height: 100vh;
  padding: 2em;
}
.container-redeen-nights {
  background: var(--fondo-defecto);
  padding: 2em 4em;
  border-radius: 2em;
  height: 400px;
  margin: 100px auto 0 auto;
  max-width: 1200px;
}
.container-redeen-nights.agreements {
  height: fit-content;
}

.title-redeen-nights {
  color: var(--azul-gac);
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  font-style: italic;
  margin: 0 0 1em 0;
}

.image-slider-nights {
  width: 100%;
  height: 200px;
  border-radius: 1em;
  position: relative;
  object-fit: fill;
}
.ciudad-nights {
  position: absolute;
  bottom: 40px;
  right: 0;
  background: var(--fondo-defecto);
  color: var(--azul-gac);
  padding: 0.2em 1.5em;
  border-radius: 2em 0 0 2em;
  font-weight: bold;
  z-index: 1;
  min-width: 40%;
  width: fit-content;
}
.name-hotel-nights {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgb(0 56 147 / 65%);
  color: white;
  padding: 0.5em;
  font-weight: bold;
  z-index: 1;
  width: 100%;
  border-radius: 0 0 1em 1em;
  text-align: center;
}
/* .container-agreements {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2em;
} */
.container-agreements section:hover {
  transform: scale(1.05);
  transition: all 0.2s;
  cursor: pointer;
}
.add-relative {
  position: relative;
}
.add-relative.alianzaplus {
  display: inline-block;
}
.btn-alianza-plus {
  border: none;
  background: var(--azul-gac);
  border-radius: 2em;
  bottom: 10%;
  color: var(--fondo-defecto);
  font-size: 1.6vw;
  font-weight: 900;
  padding: 0.1em 2em;
  position: absolute;
  left: 30%;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.add-relative.alianzaplus:hover .btn-alianza-plus {
  transform: scale(1.1);
  box-shadow: 0 0 50px var(--amarillo-gac);
}

.container-agreements.desktop {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2em;
}

.container-agreements.mobile {
  display: none;
}

.slide-pair {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
@media screen and (max-width: 1000px) {
  .bg-azul-degrade {
    padding: 0.5em;
  }
  .container-redeen-nights {
    padding: 2em 1em;
  }
  .container-agreements.desktop {
    display: none;
  }
  .container-agreements.mobile {
    display: block;
  }
  .btn-alianza-plus {
    padding: 1em 2em;
    font-size: large;
    left: 5%;
  }
}
